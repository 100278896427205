import axios from "axios";

const alumnos = {
  state: {

  },
  mutations: {

  },
  actions: {
    getAlumnosPorCentro({ commit, state }, { centro }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/alumnos/${centro}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getAlumnosPorProvincia({ commit, state }, { provincia }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/consultar_alumnos/${provincia}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getPruebasPorProvincia({ commit, state }, { provincia }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/pruebas/${provincia}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getTodasPruebas({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/pruebas`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getAlmunosCSV({ commit, state }, { id, isProvincia }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/${isProvincia ? 'descargar_credenciales_centros' : 'descargar_credenciales'}/${id}`,
          {
            responseType: 'blob',
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0',
              'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
          }
        ).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    crearAlumno({ commit, state }, { data }) {
      return new Promise((resolve, reject) => {
          axios.post(`${process.env.VUE_APP_API_URL}/alumno`, data).then(response => {
              resolve(response.data);
          }).catch(error => {
              reject(error);
          });
      });
    },
    eliminarAlumno({ commit, state }, { data }) {
      return new Promise((resolve, reject) => {
          axios.delete(`${process.env.VUE_APP_API_URL}/alumno_delete/${data.centroId}/${data.alumnoId}`).then(response => {
              resolve(response.data);
          }).catch(error => {
              reject(error);
          });
      });
    },
    editarAlumno({ commit, state }, { data }) {
      console.log(data);
      return new Promise((resolve, reject) => {
          axios.put(`${process.env.VUE_APP_API_URL}/alumno_edit/${data.centroId}/${data.alumnoId}`, data.alumno).then(response => {
              resolve(response.data);
          }).catch(error => {
              reject(error);
          });
      });
    }
  }
}

export default alumnos;

